












































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

import { Seminar, Submission } from "@/core/models";

@Component
export default class VideoMenu extends Vue {
  @Prop({ default: () => true }) canShare!: boolean;
  @Prop({ default: () => undefined }) submission?: Submission;
  @Getter("seminars/selected") seminar?: Seminar;
  @Getter("profile/id") userId!: string;
  @Action("seminars/toggleShared") toggleShared!: (id: number) => Promise<void>;
  @Action("seminars/removeSubmission")
  removeSubmission!: (id: number) => Promise<void>;

  get canView() {
    return (
      this.submission?.userId === this.userId ||
      this.userId === this.seminar?.ownerId
    );
  }

  get isShared() {
    return (this.submission as any).isShared || false;
  }

  sharing = false;
  async callToggle() {
    if (!this.submission || this.sharing) return;
    this.sharing = true;
    await this.toggleShared(this.submission.id);
    this.$emit("update");
    this.sharing = false;
  }

  removing = false;
  removeDialog = false;
  async remove() {
    if (!this.submission || this.removing) return;
    this.removing = true;
    await this.removeSubmission(this.submission.id);
    this.$emit("update");
    this.removing = false;
    this.removeDialog = false;
  }
}
