import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.canView)?_c(VMenu,{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":"","loading":_vm.sharing || _vm.removing}},on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,931124527)},[_c(VList,[_c(VListItem,{attrs:{"disabled":!_vm.canShare && !_vm.isShared},on:{"click":_vm.callToggle}},[_vm._v(" "+_vm._s(_vm.isShared ? _vm.$t("seminars.video_presentation.unshare") : _vm.$t("seminars.video_presentation.share"))+" ")])],1)],1):_vm._e(),(_vm.canView)?_c(VDialog,{attrs:{"max-width":"400px"},model:{value:(_vm.removeDialog),callback:function ($$v) {_vm.removeDialog=$$v},expression:"removeDialog"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("seminars.video_presentation.delete")))]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t("seminars.video_presentation.delConfirm"))+" ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"elevation":"0"},on:{"click":function($event){_vm.removeDialog = false}}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.cancel")))])]),_c(VBtn,{attrs:{"elevation":"0","color":"primary"},on:{"click":_vm.remove}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.confirm")))])])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }