




















































































































































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import { EditSeminarRequest, Seminar, SeminarBlock } from "@/core/models";

import Sidebar from "../components/Sidebar.vue";
import Participants from "../components/Participants.vue";
import Block from "../components/admin/Block.vue";
import BlockDialog from "../components/admin/BlockDialog.vue";

@Component({ components: { Sidebar, Participants, Block, BlockDialog } })
export default class SeminarDetails extends Vue {
  @Getter("profile/id") userId!: string;
  @Getter("seminars/loading") loading!: boolean;
  @Getter("seminars/adding") adding!: boolean;
  @Getter("seminars/selected") seminar?: Seminar;
  @Getter("seminars/blocks") blocks!: SeminarBlock[];
  @Getter("seminars/currentLogo") seminarLogo?: string;

  @Action("seminars/revertOrder") revertOrder!: () => void;
  @Action("seminars/updateOrder") updateOrder!: () => Promise<void>;
  @Action("seminars/get") getSeminar!: (d: any) => Promise<void>;
  @Action("seminars/remove") removeSeminar!: (id: number) => Promise<void>;
  @Action("seminars/removeLogo") removeLogoAction!: (d: any) => Promise<void>;
  @Action("seminars/edit")
  editSeminar!: (s: EditSeminarRequest) => Promise<void>;

  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  // seminar
  seminarDialog = false;
  editing = false;
  newSeminar: Seminar | null = null;
  logo: File | null = null;
  preview: string | null = null;
  openEditSeminar() {
    if (!this.seminar) return;
    this.editing = true;
    this.seminarDialog = true;
    this.newSeminar = JSON.parse(JSON.stringify(this.seminar));
  }
  closeSeminarDialog() {
    this.editing = false;
    this.newSeminar = null;
    this.seminarDialog = false;
  }
  async removeLogo() {
    if (!this.seminar) return;
    await this.removeLogoAction(this.seminar.id);
  }
  async confirmSeminarChanges() {
    if (!this.seminar || !this.editing || !this.newSeminar) return;

    if (!this.newSeminar.name) {
      this.displaySnackbar("Must provide title for the seminar");
      return;
    }

    this.seminarDialog = false;
    try {
      console.debug(this.newSeminar);
      await this.editSeminar({
        logo: this.logo,
        seminarId: this.seminar.id,
        name: this.newSeminar.name.trim(),
        description: this.newSeminar.description?.trim() || "",
      });
    } catch (error) {
      console.error(error);
    }
    this.newSeminar = null;
  }

  handleFileChanged(f: File | null) {
    if (!f) this.preview = null;
    else {
      const reader = new FileReader();
      reader.onload = _ => {
        this.preview = reader.result as string;
      };
      reader.readAsDataURL(f);
    }
  }

  deleteDialog = false;
  async deleteSeminar() {
    if (!this.newSeminar) return;
    this.seminarDialog = false;
    await this.removeSeminar(this.newSeminar.id);
    this.deleteDialog = false;
    this.$router.replace("/seminars");
  }

  // blocks
  blockDialog = false;
  newBlock: SeminarBlock | null = null;
  openAddBlock() {
    if (!this.seminar) return;
    this.blockDialog = true;
    this.newBlock = {
      id: 0,
      seminarId: this.seminar.id,
      title: "",
      description: "",
      elements: [],
    };
  }

  // updating seminar order
  reorderLoading = false;
  async confirmReorder() {
    this.reorderLoading = true;
    await this.updateOrder();
    this.reorderLoading = false;
  }

  // buttons
  get isAdmin() {
    return this.seminar?.ownerId === this.userId;
  }
  goToSeminar() {
    if (!this.seminar) return;
    this.$router.push(`/seminars/details/${this.seminar.id}`);
  }
  goToDash() {
    if (!this.seminar) return;
    this.$router.push(`/seminars/dashboard/${this.seminar.id}`);
  }

  created() {
    const { id } = this.$route.params;
    this.getSeminar({ id: Number(id) });
  }
}
